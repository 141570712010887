import React from "react";
import { SEO, Layout } from "../components";
import { Wrapper, Container } from "../ui/LayoutComponents";
import { PageTitle, Text } from "../ui/Texts";

const DonPage = () => (
  <Layout>
    <SEO title="Faire un don" />
    <PageTitle>FAIRE UN DON</PageTitle>
    <Wrapper>
      <iframe
        title="Formulaire de don HelloAsso"
        id="haWidget"
        allowtransparency="true"
        scrolling="auto"
        src="https://www.helloasso.com/associations/lhf-espoir/formulaires/1/widget"
        style={{ width: "100%", height: "1700px", border: "none", marginBottom: "3rem" }}></iframe>
      <Container>
        <Text center>
          Si le formulaire de don ne s'affiche pas,{" "}
          <a href="https://www.helloasso.com/associations/lhf-espoir/formulaires/1/widget">cliquez ici</a>
        </Text>
      </Container>
    </Wrapper>
  </Layout>
);

export default DonPage;
